export default () => {
  const menu = document.getElementById('menu');
  const hamburger = [...document.querySelectorAll('.hamburger')];
  const hamburgerWrapper = document.querySelector('.header-item--menu');

  if (!menu || hamburger.length === 0) return;

  hamburger.forEach((icon) => {
    icon.addEventListener('click', () => {
      menu.classList.toggle('open-menu');
      hamburgerWrapper.classList.toggle('header-item--close-menu');
    });
  });
};
