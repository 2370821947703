import FontFaceObserver from 'fontfaceobserver';

const Roboto = new FontFaceObserver('Roboto');
Roboto.load()
  .then(() => {
    document.body.classList.add('Roboto-loaded');
  })
  .catch((err) => {
    document.body.classList.add('Roboto-failed');
  });
