// import './expanding_panels.scss';

export default () => {
  const elms = [...document.querySelectorAll('[data-expanding-panel]')];
  if (elms.length > 0) {
    import(
      /* webpackChunkName: "expanding_panels" */ './expanding_panels'
    ).then(({ default: ExpandingPanels }) => {
      ExpandingPanels();
    });
  }
};
