// import '@babel/polyfill';
// import './pollyfills';
import './load_service_worker';
import './font_observer';
import { start as startUjs } from '@rails/ujs';
import lozad from 'lozad';
// import { start as activestorage } from '@rails/activestorage'; // Only add if required

// See - https://github.com/morsedigital/morse-utils for details
import { EventListener, Cookiebar } from '@morsedigital/morse-utils';
import Carousel from '@morsedigital/vanilla-carousel';
// import '@morsedigital/vanilla-carousel/carousel.scss';
// import '@morsedigital/vanilla-carousel/assets/carousel.svg';
import ExpandingPanel from './expanding_panels';
import Hamburger from './hamburger';
import MobileNav from './mobile_navigation';

(() => {
  startUjs(); // Start UJS
  // activestorage(); // Start Activestorage
})();

// (() => {
//   const modules = [Cookiebar('cookie-prompt')];
//
//   EventListener(modules);
// })();

// Lazy load images
(() => {
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();
})();

// Carousel
(() => {
  Carousel();
})();

// Expanding Content
(() => {
  ExpandingPanel();
})();

// Hamburger
(() => {
  Hamburger();
})();

// Mobile Navigation
(() => {
  MobileNav();
})();
