import * as bootstrap from "bootstrap"
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";

import "./controllers"
import './modules';



// Images - Imports every image from image folder
function importAll(r) {
  return r.keys().map(r);
}

// importAll(require.context('../images/.', true, /\.(png|gif|jpe?g|svg)$/));
